@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
    background-color: white;
    color: black;
}

.modal-content {
    background-color: #242424 !important;
    color: white !important;
}

.button-gradient {
    color: white;
    background-color: #DBC8BE;
    /* background-image: linear-gradient(90deg, #3d3d3d 20%, #dbc8be 60%, #ff8a56 80%, #ffa84b); */
}

.button-gradient2 {
    color: white;
    background-color: black;
    /* background-image: linear-gradient(150deg, #3d3d3d 20%, #dbc8be 60%, #ff8a56 80%, #ffa84b); */
}

.apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-tooltip-title,
.apexcharts-xaxistooltip {
    background-color: #242424 !important;
    color: #fff !important;
}

.grid-cols-7 {
    grid-template-columns: repeat(7, 1fr);
}

.tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 15px;
    position: absolute;
    z-index: 100;
    bottom: 180%;
    left: 50%;
    margin-left: -200px;
    box-shadow: 0 0 3px #1C1A2640;
    font-size: 14px;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, .4));
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, .4));
}

.tooltiptext2 {
    visibility: hidden;
    width: 400px;
    background-color: white;
    color: #000;
    border-radius: 6px;
    padding: 15px;
    position: absolute;
    top: -200%;
    left: -415px;
    z-index: 2;
    box-shadow: 0 0 3px #1C1A2640;
    font-size: 14px;
}

.tooltiptext2::after {
    content: "";
    position: absolute;
    top: 21%;
    right: -29px;
    transform: translate(0px, -21%);
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, .4));
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bx {
    font-family: 'boxicons' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bx-error:before {
    content: '\ea3a';
}

::after,
::before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

@media only screen and (max-width: 700px) {
    .tooltiptext {
        width: 300px;
        margin-left: -245px;
    }

    .tooltiptext::after {
        margin-left: 27%;
    }

    .tooltiptext2 {
        width: 240px;
        top: -320%;
        left: -253px;
    }
}

@media only screen and (min-width: 767px) {
    .pr-base {
        padding-right: 16px;
    }
}